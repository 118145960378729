var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.content)?_c('div',{staticClass:"nav-promo-group-mobile-item"},[_c('LinkTag',{attrs:{"interstitial":"","to":_vm.getLink},nativeOn:{"click":function($event){return _vm.handleClick.apply(null, arguments)}}},[_c('div',{staticClass:"nav-promo-group-mobile-item__image-sizer mb-4",class:`nav-promo-group-mobile-item__image-sizer--${_vm.appearance}`},[(_vm.getBackgroundImage)?_c('bleach-image',{staticClass:"nav-promo-group-mobile-item__image",attrs:{"src":_vm.getBackgroundImage.original_secure_url,"sizes":{
          sm: [105, `ar_${_vm.getAspectRatio}`],
          md: [105, `ar_${_vm.getAspectRatio}`]
        },"default-size":[105, `ar_${_vm.getAspectRatio}`],"immediate":""}}):_vm._e(),(_vm.getImage)?_c('bleach-image',{staticClass:"nav-promo-group-mobile-item__image",class:{
          'nav-promo-group-mobile-item__image--small':
            _vm.getIsSmall && _vm.getBackgroundImage
        },attrs:{"src":_vm.getImage.original_secure_url,"sizes":{
          sm: [105, `ar_1`],
          md: [105, `ar_1`]
        },"default-size":[105, `ar_1`],"alt":_vm.content.title,"immediate":""}}):_vm._e()],1),_c('div',{staticClass:"nav-promo-group-mobile-item__text"},[_c('div',{staticClass:"nav-promo-group-mobile-item__title text-commerce-tag text--clr-black"},[_vm._v(" "+_vm._s(_vm.getTitle)+" ")])])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }