<template>
  <div v-if="content" class="nav-promo-group-mobile-item">
    <LinkTag @click.native="handleClick" interstitial :to="getLink">
      <div
        class="nav-promo-group-mobile-item__image-sizer mb-4"
        :class="`nav-promo-group-mobile-item__image-sizer--${appearance}`"
      >
        <bleach-image
          v-if="getBackgroundImage"
          class="nav-promo-group-mobile-item__image"
          :src="getBackgroundImage.original_secure_url"
          :sizes="{
            sm: [105, `ar_${getAspectRatio}`],
            md: [105, `ar_${getAspectRatio}`]
          }"
          :default-size="[105, `ar_${getAspectRatio}`]"
          immediate
        />
        <bleach-image
          v-if="getImage"
          class="nav-promo-group-mobile-item__image"
          :class="{
            'nav-promo-group-mobile-item__image--small':
              getIsSmall && getBackgroundImage
          }"
          :src="getImage.original_secure_url"
          :sizes="{
            sm: [105, `ar_1`],
            md: [105, `ar_1`]
          }"
          :default-size="[105, `ar_1`]"
          :alt="content.title"
          immediate
        />
      </div>
      <div class="nav-promo-group-mobile-item__text">
        <div
          class="nav-promo-group-mobile-item__title text-commerce-tag text--clr-black"
        >
          {{ getTitle }}
        </div>
      </div>
    </LinkTag>
  </div>
</template>

<script>
import GTMAPI from "@/integration/GTMAPI"

export default {
  props: {
    content: Object,
    appearance: String
  },

  methods: {
    handleClick() {
      GTMAPI.trackEvent("navigation.promo.clicked", {
        item_title: this.content.title
      })
    }
  },

  computed: {
    getAspectRatio() {
      return this.getIsSmall ? 2 : 1
    },

    getIsSmall() {
      if (this.appearance === "card-small") {
        return true
      } else {
        return false
      }
    },

    getBackgroundImage() {
      return this.content.heroBackground && this.content.heroBackground[0]
    },

    getImage() {
      return (
        (this.getIsSmall &&
          this.content.background &&
          this.content.background[0]) ||
        (this.content.linkImage && this.content.linkImage[0]) ||
        (this.content.images && this.content.images[0])
      )
    },

    getLink() {
      return {
        name: this.content.__typename,
        params: { slug: this.fullSlugFromEntry(this.content) }
      }
    },

    getTitle() {
      return this.content.displayTitle || this.content.title
    }
  }
}
</script>

<style lang="scss">
.nav-promo-group-mobile-item {
  position: relative;

  &__image-sizer {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;

    &--card-small {
      padding-bottom: 50%;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &--small {
      img {
        object-fit: contain;
      }
    }
  }
}
</style>
