<template>
  <div class="nav-promo-mobile" v-if="items">
    <NavigationMenuPromoGroupCarousel
      v-if="items.length >= minCarouselItems"
      :items="items"
      :appearance="appearance"
    />
    <div v-else class="nav-promo-mobile__static flex--row-center-start">
      <div
        class="nav-promo-mobile__static-item"
        v-for="(item, index) in items"
        :key="index"
      >
        <NavigationMenuPromoGroupMobileItem
          :content="item"
          :appearance="appearance"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NavigationMenuPromoGroupCarousel from "./NavigationMenuPromoGroupCarousel.vue"
import NavigationMenuPromoGroupMobileItem from "./NavigationMenuPromoGroupMobileItem.vue"

export default {
  data: () => ({
    minCarouselItems: 4
  }),

  components: {
    NavigationMenuPromoGroupCarousel,
    NavigationMenuPromoGroupMobileItem
  },

  props: {
    items: Array,
    appearance: String
  }
}
</script>

<style lang="scss">
$item-margin: 8px;

.nav-promo-mobile {
  position: relative;
  width: 100%;

  &__static {
    position: relative;
    gap: 8px;
    // margin: -$item-margin;
  }

  &__static-item {
    // margin: 0 $item-margin;
    width: 33.33%;
    flex: 0 1 33.33%;
    // width: calc(33.33% - (2 * #{$item-margin}));
    // flex: 0 0 calc(33.33% - (2 * #{$item-margin}));

    // &:last-child {
    //   margin-right: unset;
    // }
  }
}
</style>
